export const UAT = 'uat';
export const PRODUCTION = 'production';

export const isProd =
	process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_NEXT_ENV !== 'uat';

export const S3_IMAGE_CDN = isProd
	? process.env.NEXT_PUBLIC_S3_IMAGE_CDN || 'https://image.jracademy.com.au'
	: '';

export const JR_IMAGE = 'jr-image';

export const GA_MEASUREMENT_ID = 'G-5YLJJB3LHZ';

export const MC_MEASUREMENT_ID = 'msuns6hjxf';
