// export const DEVELOPMENT_API_URL = 'https://uat-api.jiangren.com.au';
export const DEVELOPMENT_API_URL = 'http://localhost:3010';
export const UAT_API_URL = 'https://uat-api.jiangren.com.au';
export const PRODUCTION_API_URL = 'https://api.jiangren.com.au';

export const PRODUCTION_LEARN_URL = 'https://learn.jiangren.com.au';
export const UAT_LEARN_URL = 'https://uat-learn.jiangren.com.au';
export const DEVELOPMENT_LEARN_URL = 'http://127.0.0.1:8000';

export const PRODUCTION_BASE_URL = 'https://jiangren.com.au';
export const UAT_BASE_URL = 'https://uat.jiangren.com.au';
export const DEVELOPMENT_BASE_URL = 'http://127.0.0.1:8000';

export const PRODUCTION_JOBPIN_URL = 'https://jobpin.com.au';
export const UAT_JOBPIN_URL = 'https://uat.jobpin.com.au';
export const DEVELOPMENT_JOBPIN_URL = 'http://127.0.0.1:8010';
