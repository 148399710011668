import queryString from 'query-string';

import {
	FetchSignIn,
	FetchSignInInvalidUser,
	FetchWeChatQRCode,
	FetchWeChatQRCodeStatus,
	TWechatSignInPayload
} from '@/interfaces/fetch/wechat.fetch';
import request, { HTTP_METHOD } from '@/utils/request';

/**
 * 获取微信二维码
 */
export const fetchQRCode = async (params: {
	/** 使用场景 */
	sceneStr: string;
	/** userId， 需要绑定账号时使用 */
	userId?: string;
}): Promise<FetchWeChatQRCode> => {
	const query = queryString.stringify(params);
	return request<FetchWeChatQRCode>(
		HTTP_METHOD.GET,
		`https://prod-site.jiangren.com.au/api/wechat/qrcode?${query}`
	);
};

/**
 * 获取微信二维码状态
 */
export const fetchCheckQRCodeStatus = async (id: string): Promise<FetchWeChatQRCodeStatus> => {
	return request<FetchWeChatQRCodeStatus>(
		HTTP_METHOD.GET,
		`https://prod-site.jiangren.com.au/api/wechat/qrcode/status?id=${id}`
	);
};

/**
 * 微信登录账号
 */
export const fetchWeChatSignIn = async (
	data: TWechatSignInPayload
): Promise<FetchSignIn | FetchSignInInvalidUser> => {
	return request<FetchSignIn | FetchSignInInvalidUser>(
		HTTP_METHOD.POST,
		'https://prod-site.jiangren.com.au/api/wechat/user/login',
		{
			body: data
		}
	);
};

export const getWechatSignature = async (url: string): Promise<IWxSignature> =>
	request<IWxSignature>(
		HTTP_METHOD.GET,
		`https://prod-site.jiangren.com.au/api/wechat?url=${url}`
	);

/**
 * 微信授权
 */
export const fetchWechatAuth = async (): Promise<{ authUri: string }> =>
	request(HTTP_METHOD.GET, 'https://prod-site.jiangren.com.au/api/wechat/wechatAuth');

export default {};
