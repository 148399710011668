import { LinearProgress, linearProgressClasses } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
	align-items: center;
	display: flex;
	.MuiLinearProgress-root {
		border: 1px solid #000;
		border-radius: 5px;
		display: inline-block;
		height: 6px;
		margin-right: 8px;
		width: calc(100% - 50px);
	}
	.${linearProgressClasses.colorPrimary} {
		background-color: #fff;
	}
	.${linearProgressClasses.bar} {
		background-color: #10162f;
		border-radius: 5px;
	}
`;

interface ProgressBarProps {
	percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
	return (
		<Container>
			<LinearProgress variant="determinate" value={percentage} />
			{percentage}%
		</Container>
	);
};

export default ProgressBar;
