export const TOKEN = 'token';
const USER = 'user';
export const WECHAT_OPENID = 'wechat_openid';

export const CONTACT_US_FORM = 'contactUsForm';

export const WELCOME_MODAL_VISIBLE = 'welcome_modal_visible';
export const VIEWED_LIST_UNI_WELCOME_MODAL = 'viewed_list_uni_welcome_modal';

export const PROMOTION_MODAL_VISIBLE = 'promotion_modal_visible';

export const JOB_INTERVIEW_CREATE_STEP = 'job_interview_create:step';
// step 1
export const JOB_INTERVIEW_CREATE_METHOD = 'job_interview_create:method';
export const JOB_INTERVIEW_CREATE_DIFFICULTY = 'job_interview_create:difficulty';
export const JOB_INTERVIEW_CREATE_STATUS = 'job_interview_create:status';
export const JOB_INTERVIEW_CREATE_ELSE_TEXT = 'job_interview_create:else_text';
// step 2
export const JOB_INTERVIEW_CREATE_TITLE = 'job_interview_create:title';
export const JOB_INTERVIEW_CREATE_POSITION = 'job_interview_create:position';
export const JOB_INTERVIEW_CREATE_COMPANY = 'job_interview_create:company';
export const JOB_INTERVIEW_CREATE_COMPANY_NAME = 'job_interview_create:companyName';
export const JOB_INTERVIEW_CREATE_JOB_TYPE = 'job_interview_create:jobType';
// step 3
export const JOB_INTERVIEW_CREATE_ANONYMOUS = 'job_interview_create:anonymous';
export const JOB_INTERVIEW_CREATE_CONTENT = 'job_interview_create:content';
export const JOB_INTERVIEW_CREATE_RATING = 'job_interview_create:rating';

export const TICKET_SHOPPING_CART = 'ticket_shopping_cart';
export const TICKET_SHOPPING_BUYER_INFO = 'ticket_shopping_buyer_info';
export const TICKET_SHOPPING_FEEDBACK = 'ticket_shopping_feedback';

export const ADVISOR = 'advisor';

export const S3_UPLOAD_PREFIX = 's3_upload_';

export const STUDY_LESSON_SIDE_TAB = 'study_lesson_side_tab';

export default USER;
