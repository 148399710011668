import request, { HTTP_METHOD } from '@/utils/request';

export const fetchRecommendPrograms = (
	slugs: string[]
): Promise<FetchData<FetchRecommendProgram[]>> => {
	return request<FetchData<FetchRecommendProgram[]>>(
		HTTP_METHOD.GET,
		`/study/recommend-programs?${slugs.map(slug => `slug=${slug}`).join('&')}`
	);
};

export const fetchStudyProgram = (programId: string): Promise<FetchData<StudyProgram>> => {
	return request<FetchData<StudyProgram>>(HTTP_METHOD.GET, `/study/programs/${programId}`);
};

export const fetchStudyProgramLesson = (
	programId: string,
	lessonId: string
): Promise<FetchData<StudyProgramLesson>> => {
	return request<FetchData<StudyProgramLesson>>(
		HTTP_METHOD.GET,
		`/study/programs/${programId}/lessons/${lessonId}`
	);
};

export const fetchStudyMyNoteByLessonId = (
	lessonId: string
): Promise<FetchData<StudyProgramLessonNote>> => {
	return request<FetchData<StudyProgramLessonNote>>(
		HTTP_METHOD.GET,
		`/study/lessons/${lessonId}/notes/me`
	);
};

export const fetchGetLabWork = async (body: {
	labIds: string[];
	studentId: string;
}): Promise<{ labWorks: StudyLessonLabWork[] }> => {
	const result = await request<FetchData<{ labWorks: StudyLessonLabWork[] }>>(
		HTTP_METHOD.POST,
		`/study/lessons/lab/get`,
		{
			body
		}
	);
	return result;
};

export const fetchSaveAssignmentWork = (body: {
	lessonId: string;
	studentId: string;
	fileName?: string;
	fileKey?: string;
	url?: string;
}): FetchData<StudyLessonAssignmentWork> => {
	return request<FetchData<StudyLessonAssignmentWork>>(
		HTTP_METHOD.POST,
		`/study/lessons/assignment/save`,
		{
			body
		}
	);
};

export const fetchGetAssignmentWork = async (body: {
	lessonId: string;
	studentId: string;
}): Promise<StudyLessonAssignmentWork> => {
	const result = await request<FetchData<StudyLessonAssignmentWork>>(
		HTTP_METHOD.POST,
		`/study/lessons/assignment/get`,
		{
			body
		}
	);
	return result;
};

// 作业提交
export const fetchLessonWork = async (query: {
	/** lesson的作业 */
	lessonId?: string;
	/** lab的作业 */
	labId?: string;
	studentId: string;
}): Promise<IFetchLessonWork[]> => {
	const result = await request<FetchData<IFetchLessonWork[]>>(
		HTTP_METHOD.GET,
		'/study/lesson/work',
		{
			query
		}
	);
	return result;
};

export const fetchSaveLessonWork = async (
	body: SaveLessonWorkPayload
): Promise<IFetchLessonWork> => {
	const result = await request<FetchData<IFetchLessonWork>>(
		HTTP_METHOD.POST,
		'/study/lesson/work',
		{
			body
		}
	);
	return result;
};
export const fetchCreateWorkFile = async (
	body: CreateLessonWorkFilePayload
): Promise<IFetchLessonWorkFile> => {
	const result = await request<FetchData<IFetchLessonWorkFile>>(
		HTTP_METHOD.POST,
		'/study/lesson/work/file',
		{
			body
		}
	);
	return result;
};

// lesson-lab
// 用作work
/**
 * @deprecated
 */
export const fetchLabWork = async (query: {
	/** lesson的作业 */
	lessonId?: string;
	/** lab的作业 */
	labId?: string;
	studentId: string;
}): Promise<IFetchLessonLabWork[]> => {
	const result = await request<FetchData<IFetchLessonLabWork[]>>(
		HTTP_METHOD.GET,
		'/study/lesson/lab/work',
		{
			query
		}
	);
	return result;
};

// lesson-lab-work
/**
 * @deprecated
 */
export const fetchSaveLabWork = async (
	body: SaveLessonLabWorkPayload
): Promise<IFetchLessonLabWork> => {
	const result = await request<FetchData<IFetchLessonLabWork>>(
		HTTP_METHOD.POST,
		'/study/lesson/lab/work',
		{
			body
		}
	);
	return result;
};
// lesson-lab-work-file
/**
 * @deprecated
 */
export const fetchCreateLabWorkFile = async (
	body: CreateLessonLabWorkFilePayload
): Promise<IFetchLessonLabWorkFile> => {
	const result = await request<FetchData<IFetchLessonLabWorkFile>>(
		HTTP_METHOD.POST,
		'/study/lesson/lab/work/file',
		{
			body
		}
	);
	return result;
};

export const fetchProgramsList = (): Promise<IFetchPrograms> => {
	return request<FetchData<IFetchPrograms>>(HTTP_METHOD.GET, '/study/programs', {
		cache: 'no-cache'
	});
};

export const fetchCalendarData = (query: {
	start: string;
	end: string;
}): Promise<ICalendarDataItem[]> => {
	return request<FetchData<ICalendarDataItem[]>>(HTTP_METHOD.GET, '/study/calendar', {
		query,
		cache: 'no-cache'
	});
};

export default {};
