/* eslint no-useless-escape:0 */
const emailReg = /^[A-Za-z0-9\-]+([_\.][A-Za-z0-9\-]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;

/**
 * @description
 * Check whether an email address is valid
 * @param {string} email an email address
 * @return {boolean} return a boolean
 */

export const isEmail = (email: string): boolean => emailReg.test(email);

/**
 * @description
 * check validation of phone number
 * @param {string} phone a phone number
 * @return {boolean} return a boolean
 */

export const workPhoneNumber = /^0(2|3|7|8)\d{8,12}$/;
export const mobilePhoneNumber = /^(?:0?4)\d{8}$/;
export const homePhoneNumber = /^0(2|3|7|8)\d{8}$/;
export const cnMobilePhoneNumber = /^1[3-9]\d{9}$/;
export const hongKongMobilePhoneNumber = /^([6|9])\d{7}/;

export const isPhoneNumber = (phone: string, regex: RegExp = mobilePhoneNumber): boolean =>
	regex.test(phone);

/*
 * @description
 * check validation of url
 * @param {string} path a url
 * @return {boolean} return a boolean
 */

/* eslint no-useless-escape:0 */
const urlReg =
	/(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path: string) {
	return urlReg.test(path);
}

/*
 * @description
 * check validation of username
 * @param {string} name a username
 * @return {boolean} return a boolean
 */
const userNameReg = /^[\u4e00-\u9fa5a-zA-Z\s]+$/;

export const isValidUserName = (name: string): boolean => {
	return userNameReg.test(name);
};

/**
 * @description
 * check validation of password
 * @param {string} password a password
 * @return {boolean} return a boolean
 */
const passwordRegex =
	/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;

export const passwordValidator = (password: string): boolean => passwordRegex.test(password);

export default {};
