import { UserSource } from '@/constants/user';
import request, { HTTP_METHOD } from '@/utils/request';

const getStaff = async () =>
	request(HTTP_METHOD.GET, 'https://prod-site.jiangren.com.au/api/users/staff');

export const fetchUserSignIn = async (params: {
	email: string;
	password: string;
}): Promise<FetchSignIn> => {
	const result = await request<FetchData<FetchSignIn>>(HTTP_METHOD.POST, '/account/sign-in', {
		body: params
	});
	return result;
};

export const fetchUserSignUp = async (params: FetchSignUpRequest): Promise<FetchSignIn> => {
	const result = await request<FetchData<FetchSignIn>>(HTTP_METHOD.POST, '/account/sign-up', {
		body: params
	});
	return result;
};

export const fetchUserPasswordUpdate = async (
	params: FetchPasswordUpdateRequest
): Promise<FetchPasswordUpdateRequest> => {
	const result = await request<FetchData<FetchPasswordUpdateRequest>>(
		HTTP_METHOD.POST,
		'/account/password-update',
		{
			body: params
		}
	);
	return result;
};

export const fetchCheckEmail = async (email: string): Promise<{ status: boolean }> => {
	const result = await request<FetchData<{ status: boolean }>>(
		HTTP_METHOD.POST,
		`/account/check-email`,
		{
			body: {
				email
			}
		}
	);
	return result;
};

export const fetchUserSignUpEmailVerification = async (params: {
	token: string;
}): Promise<FetchSignUpEmailVerification> => {
	const result = await request<FetchData<FetchSignUpEmailVerification>>(
		HTTP_METHOD.POST,
		'/account/sign-up-verify-email',
		{
			body: params
		}
	);
	return result;
};

/**
 * 发送忘记密码邮件
 */
export const fetchSendForgetEmail = async (params: { email: string }): Promise<FetchSendEmail> => {
	const result = await request<FetchData<FetchSendEmail>>(
		HTTP_METHOD.POST,
		`/account/forget-email/${UserSource.JIANGREN}`,
		{
			body: params
		}
	);
	return result;
};

/**
 * 忘记密码-重置密码
 */
export const fetchResetPassword = async (params: { token: string; password: string }) => {
	const result = await request<FetchData<void>>(HTTP_METHOD.POST, '/account/password-reset', {
		body: params
	});
	return result;
};

export const fetchUserSignOut = (params: { userId: string }) => {
	return request(
		HTTP_METHOD.POST,
		`https://prod-site.jiangren.com.au/api/users/removeOneTimeToken?userId=${params.userId}`,
		{
			body: params
		}
	);
};

export const fetchUpdateUser = (data: FetchUpdateUserRequest) => {
	return request<FetchMe>(HTTP_METHOD.PATCH, '/account', {
		body: data
	});
};

export const fetchMe = async (token?: string): Promise<FetchMe> => {
	return request<FetchMe>(HTTP_METHOD.GET, '/account/me', {
		token,
		cache: 'no-cache'
	});
};

export const updateUserEmail = async (params: {
	email: string;
	code: string;
}): Promise<FetchStatus> => {
	return request<FetchStatus>(HTTP_METHOD.PATCH, '/account/me/email', {
		body: params
	});
};

export const updateUserGatherEmailApi = async (params: {
	email: string;
	code: string;
}): Promise<FetchStatus> => {
	return request<FetchStatus>(HTTP_METHOD.PATCH, '/account/me/email/gather', {
		body: params
	});
};

export const deleteAccount = async (params: { password: string }): Promise<FetchStatus> => {
	return request<FetchStatus>(HTTP_METHOD.DELETE, '/account/me', {
		body: params
	});
};

export { getStaff as default };
